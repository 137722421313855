<template>
  <div class="modal">
    <el-form ref="formRef" :model="{ contract_fee_id, orginInfoList, count }">
      <el-row :gutter="20">
        <div class="header">
          <slot name="header">
            <div>新增附属合同</div>
          </slot>
        </div>
        <el-col :span="12">
          <el-form-item label="合同编号" label-width="80px">
            <div>
              {{ formData.contract_no }}
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="合同时间" label-width="80px">
            <el-date-picker v-model="contract_time" start-placeholder="合同开始时间段" end-placeholder="合同时间段" type="daterange"
              value-format="yyyy-MM-dd"></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="收费项目" label-width="80px" prop="contract_fee_id" :rules="{
            required: isDisable ? false : true,
            message: '收费项目不能为空',
            trigger: 'blur',
          }"><el-select :disabled="isDisable" v-model="contract_fee_id" clearable placeholder="选择收费项目"
              @change="hanldeChangeFee">
              <el-option v-for="item in feeList" :key="item.id" :value="item.id"
                :label="item.contract_fee_name"></el-option> </el-select></el-form-item>
        </el-col>
      </el-row>
      <template v-if="repeatedType == 10">
        <el-row :gutter="20" v-for="(item, index) of stallList" :key="index">
          <el-col :span="6">
            <el-form-item label="商户编号" label-width="80px">
              <el-input disabled v-model="item.merchants_stall_number" />
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="isShowCycle">
            <el-form-item label="月租金" label-width="80px">
              <el-input :disabled="isDisable" v-model="item.merchants_stall_price" @blur="hanldeMonthBlur" />
            </el-form-item>
          </el-col> </el-row></template>
      <div v-if="repeatedType == 10">
        <el-row :gutter="20">
          <el-col :span="6" v-if="contract_fee_id && isShowCycle">
            <el-form-item label="缴费周期" label-width="80px" :rules="{
              required: isDisable ? false : true,
              trigger: 'blur',
              validator: checkOnceNumber,
            }" prop="count">
              <el-input-number :disabled="isDisable" :controls="false" v-model="count" @blur="hanldeBlur" :step="1"
                :min="0" class="yc-input-number" />
            </el-form-item>
          </el-col>
          <el-col :span="6" v-if="contract_fee_id && isShowCycle">
            <el-form-item label="总金额" label-width="80px">
              <el-input :controls="false" v-model="feeTotal" class="yc-input-number" />
            </el-form-item>
          </el-col>
        </el-row>
        <div v-for="(item, index) of orginInfoList" :key="index">
          <el-row :gutter="20">
            <!-- <el-col :span="3">
              <el-form-item label="原价格" label-width="80px">
                <el-input v-model="item.origin_price" disabled />
              </el-form-item>
            </el-col> -->
            <el-col :span="3">
              <el-form-item label="现价格" label-width="80px" :prop="'orginInfoList.' + index + '.pay_price'" :rules="{
                required: isDisable ? false : true,
                trigger: 'blur',
                validator: checkNumber,
              }">
                <el-input-number :disabled="isDisable" placeholder="请输入现价格" :controls="false" v-model="item.pay_price"
                  class="yc-input-number"></el-input-number> </el-form-item></el-col>
            <el-col :span="6">
              <el-form-item label="收费时间" label-width="80px" :prop="'orginInfoList.' + index + '.date'" :rules="{
                required: isDisable ? false : true,
                message: '收费时间不能为空',
                trigger: 'blur',
              }">
                <el-date-picker v-model="item.date" type="date" placeholder="选择日期时间" value-format="yyyy-MM-dd"
                  :picker-options="pickerRechargeOptions">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="开始时间" label-width="80px">
                <el-input :controls="false" :value="startTimeList[index]"
                  class="yc-input-number"></el-input></el-form-item></el-col>

            <el-col :span="6">
              <el-form-item label="结束时间" label-width="80px" :prop="'orginInfoList.' + index + '.property_end_time'"
                :rules="{
                  required: isDisable ? false : true,
                  message: '结束时间不能为空',
                  trigger: 'blur',
                }">
                <el-date-picker v-model="item.property_end_time" type="date" placeholder="选择日期时间"
                  value-format="yyyy-MM-dd" :picker-options="pickerOptions" @focus="hanldeDatePicker(index)"
                  @change="hanldeDateChange(item, index, $event)">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </div>
      <div v-if="repeatedType == 20">
        <div v-for="(item, index) of orginInfoList" :key="index">
          <el-row :gutter="20">
            <el-col :span="6">
              <el-form-item label="价格" label-width="80px" :prop="`orginInfoList.${index}.pay_price`" :rules="{
                required: isDisable ? false : true,
                message: '金额不能为空',
                trigger: 'blur',
                validator: checkOnceNumber,
              }">
                <el-input-number :disabled="isDisable" placeholder="请输入现价格" :controls="false" v-model="item.pay_price"
                  class="yc-input-number"></el-input-number> </el-form-item></el-col>
            <el-col :span="6">
              <el-form-item label="收费时间" label-width="80px" :prop="'orginInfoList.' + index + '.date'" :rules="{
                required: isDisable ? false : true,
                message: '收费时间不能为空',
                trigger: 'blur',
              }">
                <el-date-picker :disabled="isDisable" v-model="item.date" type="date" placeholder="选择日期时间"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item> </el-col></el-row>
        </div>
      </div>

      <el-row :gutter="20">
        <el-col :span="24">
          <el-form-item label="备注" label-width="80px">
            <el-input :disabled="isDisable" v-model="formData.remarks" type="textarea" :rows="2" placeholder="请输入内容" />
          </el-form-item>
        </el-col>
        <el-col :span="24" v-if="formData.changeStatus == 20 || isDetail">
          <el-form-item label="理由" label-width="80px">
            <el-input :disabled="isDetail" v-model="formData.contract_desc" type="textarea" :rows="2"
              placeholder="请输入内容" /> </el-form-item></el-col>
      </el-row>
    </el-form>
    <div class="footer">
      <MyButton class="button" @click="() => {
        $emit('cancel');
      }
        ">
        <span>取消</span>
      </MyButton>
      <MyButton class="button" left @click="onClick" v-if="!isDetail">
        <span>确认</span>
      </MyButton>
    </div>
  </div>
</template>

<script>
import { getTotal } from "../../../../plugin/computed";
import dayjs from "dayjs";
export default {
  props: {
    attachForm: {
      type: Object,
      default: () => ({}),
    },
    feeList: {
      type: Array,
      default: () => [],
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
    isDetail: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    attachForm: {
      handler (newValue) {
        this.contract_time = [newValue.contract_start, newValue.contract_end];
        this.formData = { ...newValue };
        this.contract_fee_id = newValue.contract_fee_id;
        this.remarks = newValue.remarks;
        this.orginInfoList =
          newValue.contract_rent_info.length > 0
            ? JSON.parse(newValue.contract_rent_info)
            : [];
        this.stallList =
          this.formData.contract_stall_info?.length > 0
            ? JSON.parse(this.formData.contract_stall_info)
            : [];
        this.count = this.orginInfoList.length;
        if (this.count) {
          this.orginInfoList[this.orginInfoList.length - 1].property_end_time =
            newValue.contract_end;
          this.isShowCycle =
            this.orginInfoList[this.orginInfoList.length - 1].origin_price == 0
              ? true
              : false;
          this.type = this.isShowCycle ? 10 : 0;

          this.startTimeList = this.orginInfoList.map(
            (item) => item.property_time
          );
          this.calc(true);
        }
      },
      deep: true,
    },
    contract_fee_id (newValue) {
      if (!newValue) return;
      this.repeatedType = this.feeList.find(
        (item) => item.id == newValue
      ).repeated_type;
    },
    isDisable () {
      this.$refs.formRef?.clearValidate();
    },
  },
  data () {
    return {
      count: 0,
      feeTotal: 0,
      contract_fee_id: "",
      contract_time: [],
      time: null,
      type: 0, //10为没有收费项 20为有收费项
      orginInfoList: [],
      saveSource: [],
      formData: {},
      repeatedType: 0, //repeated_type == 10 ? '多次收费' : '一次性收费'
      isShowCycle: false, //有数据不准更改周期 用来判断是否为自己输入商户金额
      stallList: [], //具体缴费项目 商铺信息
      datePicker: [], //保存时间信息 [开始时间 ， 结束时间 ， 开始时间，结束] 结束时间索引*2存储
      clickDateInfo: [], //点开的时间选择器时间区间['2022-10-1' , '2022-12-1']
      startTimeList: [], //单独存开始时间
      pickerOptions: {
        disabledDate: (time) => {
          const timestamp = time.getTime();
          const firstStamp = new Date(this.clickDateInfo[0]).getTime();
          const nextStamp = new Date(this.clickDateInfo[1]).getTime();
          const result = timestamp <= firstStamp || timestamp >= nextStamp;
          return result;
        },
      },

      //缴费时间限制
      pickerRechargeOptions: {
        disabledDate: (time) => {
          const timestamp = time.getTime();
          const day = dayjs(this.contract_time[0])
            .add(-1, "d")
            .format("YYYY-MM-DD");
          const firstStamp = new Date(day).getTime();
          const nextStamp = new Date(this.contract_time[1]).getTime();
          const result = timestamp <= firstStamp || timestamp >= nextStamp;
          return result;
        },
      },
    };
  },

  methods: {
    calc (flag = false) {
      let temp = 0;
      this.stallList?.forEach((item) => {
        temp += Number(item.merchants_stall_price);
      });

      this.feeTotal = getTotal(
        this.contract_time[0],
        this.contract_time[1],
        temp
      );
      !flag && this.allotPrice();
    },
    allotPrice () {
      if (this.orginInfoList.length == 1) {
        this.orginInfoList[0].pay_price = this.feeTotal;
        return;
      }
      let total = 0;
      this.orginInfoList.forEach((item, index) => {
        if (index != this.orginInfoList.length - 1) {
          item.pay_price = Math.floor((this.feeTotal / this.count) * 100) / 100;
          total += item.pay_price;
        } else {
          item.pay_price = Math.round((this.feeTotal - total) * 100) / 100;
        }
      });
    },
    checkOnceNumber (rules, value, next) {
      if (this.isDisable) {
        next();
        return;
      }
      if (isNaN(Number(value))) {
        next(new Error("请输入大于0的数字"));
        return;
      }
      if (!value || Number(value) < 0) {
        next(new Error("请输入大于0的数字"));
        return;
      }
      next();
    },
    checkNumber (rules, value, next) {
      if (this.isDisable) {
        next();
        return;
      }
      if (isNaN(Number(value))) {
        next(new Error("请输入大于0的数字"));
        return;
      }
      if (!value || Number(value) < 0) {
        next(new Error("请输入大于0的数字"));
        return;
      }
      let total = 0;
      this.orginInfoList.forEach((item) => {
        total += Number(item.pay_price);
      });
      total = Math.round(total * 100) / 100;
      if (this.isShowCycle && total != this.feeTotal) {
        next(new Error("输入金额不等于总金额"));
        return;
      }
      if (!this.isShowCycle) {
        const filedIndex = rules.field?.split(".")[1];
        const verityItem = this.orginInfoList[filedIndex] ?? {};
        if (
          verityItem.pay_price > verityItem.origin_price &&
          verityItem.origin_price != 0
        ) {
          next(new Error("输入金额大于原金额"));
        }
      }
      next();
    },
    checkTime (rules, value, next) {
      next();
    },
    async onClick () {
      const value = {
        contract_id: this.formData.contract_id,
        contract_no: this.formData.contract_no,
        merchants_id: this.formData.merchants_id,
        contract_no: this.formData.contract_no,
        is_contract_vice: this.formData.is_contract_vice,
        remarks: this.formData.remarks,
        contract_desc: this.formData.contract_desc,
        changeStatus: this.formData.changeStatus, //前端字段保存现在需要 更改的合同状态 是否是拒绝 显示理由
        contract_start: this.contract_time[0],
        contract_end: this.contract_time[1],
        contract_rent_info: this.repeatedType != 20 ? this.orginInfoList : this.orginInfoList?.map((item) => ({ ...item, bill_id: null })) ?? [], //固定金额不穿bill_id
        contract_stall_info: this.stallList,
        contract_fee_id: this.contract_fee_id,
        contract_fee_name: this.feeList.find(
          (item) => item.id == this.contract_fee_id
        )?.contract_fee_name,
      };

      try {
        await this.$refs.formRef.validate();
        if (this.isShowCycle) {
          this.orginInfoList.forEach((item, index) => {
            if (index == 0) {
              item.property_time = this.startTimeList[0];
              item.isShowCycle = this.isShowCycle; //用来判断编辑能否编辑 商户单价
              return;
            }
            item.property_time = this.startTimeList[index];
          });
        }
        this.$emit("onSubmit", value);
      } catch (error) {
        console.log(error);
      }
    },
    hanldeChangeFee (id) {
      if (!id) {
        this.repeatedType = 0;
        return;
      }
      this.repeatedType = this.feeList.find(
        (item) => item.id == id
      ).repeated_type;
      this.getFeeDetail(id);
    },
    getFeeDetail (contract_fee_id) {
      const params = {
        contract_fee_id,
        contract_id: this.attachForm.contract_id,
        merchants_id: this.attachForm.merchants_id,
      };
      this.$request
        .HttpPost("/contract_attach/contractFeeList", params)
        .then((res) => {
          this.stallList = res.data?.list_stall ?? [];
          if (!res.data?.list_bill?.length) {
            this.type = 10;
            if (this.repeatedType == 20) {
              this.count = 1;
              this.orginInfoList = [{}];
            } else {
              this.count = 0;
              this.orginInfoList = [];
            }
            this.isShowCycle = true;
            return;
          }
          this.type = 20;
          this.orginInfoList =
            this.repeatedType == 10 ? res.data.list_bill : [{}];
          this.saveSource = [...this.orginInfoList];
          this.count = this.saveSource.length;
          this.isShowCycle = false;
          this.startTimeList = this.orginInfoList.map(
            (item) => item.property_time
          );
        });
    },
    nextTime (index, direction) {
      let count = 0;
      let time = "";
      while (!time && count < this.datePicker.length) {
        count++;
        let temp = 0;
        if (direction == "LEFT") {
          temp = -count;
        } else {
          temp = count;
        }
        time = this.datePicker[index + temp];
      }
      return time;
    },
    /**
     *
     * @param {*} item 当前时间选择器所在对象
     * @param {*} index 索引
     */
    hanldeDatePicker (index) {
      const first = this.nextTime(index, "LEFT");
      const next = this.nextTime(index, "RIGHT");
      if (index == 0) {
        this.clickDateInfo = [this.contract_time[0], next + " 00:00:00"];
        return;
      }

      this.clickDateInfo = [first + " 23:59:59", next + " 00:00:00"];
    },
    /**
     *
     * @param {*} item 当前item对象
     * @param {*} index  item 索引
     * @param {*} value  $event datePicker 选择值
     * @param {*} sort 收费时间0 结束时间1
     */
    hanldeDateChange (item, index, value) {
      if (!value) {
        this.datePicker[index] = null;
        this.$set(this.startTimeList, index + 1, null);
        return;
      }
      this.datePicker[index] = this.$common.dateDayAdd(value);
      this.$set(this.startTimeList, index + 1, this.$common.dateDayAdd(value));
    },
    hanldeMonthBlur () {
      this.calc();
    },
    hanldeBlur () {
      const tempArry = [];
      if (this.type == 10) {
        let i = 0;
        while (i < this.count) {
          i++;
          tempArry.push({
            bill_id: "",
            contract_fee_id: "",
            contract_fee_name: "",
            date: "",
            is_contract_vice: 10,
            origin_price: 0,
            pay_price: undefined,
          });
        }
        if (tempArry.length) {
          tempArry[tempArry.length - 1].property_end_time =
            this.contract_time[1];
        }

        this.orginInfoList = tempArry;
        this.startTimeList = new Array(tempArry.length).fill(null);
        this.startTimeList[0] = this.contract_time[0];
        this.datePicker = new Array(tempArry.length).fill(null);
        this.datePicker[0] = this.contract_time[0];
        this.datePicker[this.datePicker.length - 1] = this.contract_time[1];
        this.calc();
      } else {
        let i = 0;
        while (i < (this.count - this.saveSource?.length ?? 0)) {
          i++;
          tempArry.push({
            bill_id: "",
            contract_fee_id: "",
            contract_fee_name: "",
            date: "",
            is_contract_vice: 20,
            origin_price: null,
            pay_price: undefined,
          });
        }
        this.orginInfoList = this.saveSource.concat(tempArry);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  box-sizing: border-box;
  padding: 0 20px 20px 20px;

  .el-date-editor.el-input {
    width: 100%;
  }

  .el-select {
    width: 100%;
  }
}

.header {
  font-weight: bold;
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  border-bottom: 1px solid #eaeaea;
  margin-bottom: 20px;
}

.footer {
  text-align: end;
}

.yc-input-number::v-deep {
  width: 100%;

  .el-input__inner {
    text-align: start;
  }
}
</style>
