<template>
  <div class="contractlist">
    <NavBar name="批量合同导入"></NavBar>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item>
          <MyInput
            v-model="form.merchants_stall_name"
            placeholder="输入商户姓名"
          >
            <template slot="pre">商户姓名:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="form.merchants_contact" placeholder="输入联系电话">
            <template slot="pre">联系电话:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="form.merchants_card_id" placeholder="输入证件号码">
            <template slot="pre">证件号码:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="form.contract_no" placeholder="输入合同编码">
            <template slot="pre">合同编码:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="form.worker_name" placeholder="输入导入人">
            <template slot="pre">导入人:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="status"
            placeholder="状态"
            @change="searchstatus"
            clearable
          >
            <el-option :value="10" label="待维护"></el-option>
            <el-option :value="20" label="待审批"></el-option>
            <el-option :value="0" label="正常"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton class="offset" @click="getList">
            搜索
            <template slot="preImage">
              <img src="../../unit/img/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <!-- <el-form-item>
          <MyButton class="offset" @click="batchAudit">批量数据复核</MyButton>
        </el-form-item> -->
        <el-form-item>
          <MyButton
            class="offset"
            :accessId="82938"
            @click="
              () => {
                getContractTemplate();
                batchVisible = true;
              }
            "
          >
            批量合同导入
            <template slot="preImage">
              <img src="../../unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <!-- <el-form-item>
        <MyButton class="offset" :accessId="82939">
          批量数据维护
          <template slot="preImage">
            <img src="../../unit/img/down.png" alt />
          </template>
        </MyButton>
      </el-form-item>
      <el-form-item>
        <MyButton class="offset" :accessId="82940">
          批量数据复核
          <template slot="preImage">
            <img src="../../unit/img/down.png" alt />
          </template>
        </MyButton>
      </el-form-item> -->
      </el-form>
      <!-- table -->
      <div class="public-table">
        <el-table
          :data="datalist"
          ref="multipleTable"
          :header-cell-style="{
            'text-align': 'center',
            background: 'rgb(245, 245, 245)',
          }"
          :cell-style="{ 'text-align': 'center' }"
          v-loading="loading"
          :span-method="arraySpanMethod"
          height="95%"
        >
          <el-table-column type="selection"></el-table-column>
          <el-table-column
            label="商户姓名"
            prop="merchants_stall_name"
          ></el-table-column>
          <el-table-column
            label="联系电话"
            prop="merchants_contact"
            width="200px"
          ></el-table-column>
          <el-table-column
            label="证件号"
            width="200px"
            show-overflow-tooltip
            prop="merchants_card_id"
          ></el-table-column>
          <el-table-column
            label="合同编号"
            prop="contract_no"
          ></el-table-column>
          <el-table-column
            label="合同开始时间"
            prop="contract_start"
          ></el-table-column>
          <el-table-column
            label="合同到期时间"
            prop="contract_end"
          ></el-table-column>
          <el-table-column label="导入人" prop="worker_name"></el-table-column>
          <el-table-column label="状态" prop="contract_import_status">
            <template #default="{ row }">
              {{ row.contract_import_status == 10 ? "待维护" : "待审批" }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="{ row }">
              <pop
                tips="数据维护"
                v-if="row.contract_import_status == 10"
                :accessId="82939"
                @myclick="
                  $router.push(
                    `/contract/batchcontractDetail?id=${row.contract_id}`
                  )
                "
              >
                <img
                  src="../../assets/img/icon/shujuweihu.png"
                  class="icon"
                  alt
                />
              </pop>
              <pop
                popLeft
                tips="数据复核"
                :accessId="82940"
                v-if="row.contract_import_status == 20"
                @myclick="
                  $router.push(
                    `/contract/batchcontractDetail?id=${row.contract_id}`
                  )
                "
              >
                <img
                  src="../../assets/img/icon/shujufuhe.png"
                  class="icon"
                  alt
                />
              </pop>
              <pop popLeft tips="删除" @myclick="del(row)">
                <img src="../../assets/img/icon/deleted.png" class="icon" alt />
              </pop>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="public-page">
        <el-pagination
          :total="total"
          next-text="下一页"
          prev-text="上一页"
          :current-page="form.pageNum"
          :page-size="10"
          layout="total,prev,pager,next"
          @current-change="handleChangePageNum"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      title="批量合同导入"
      :visible.sync="batchVisible"
      @close="closebatchimport"
      :close-on-click-modal="false"
    >
      <el-form label-position="right" label-width="100px">
        <el-form-item label="下载模板">
          <MyButton @click="downvisible = true">模板下载</MyButton>
        </el-form-item>
        <el-form-item label="上传模板">
          <el-upload
            :on-change="batchfileChange"
            :show-file-list="false"
            action=""
            accept=".xlsx, .csv"
            ref="batchupload"
            :auto-upload="false"
          >
            <el-button icon="el-icon-upload2" type="primary">上传</el-button>
            <p v-if="fileName">{{ fileName.name }}</p>
          </el-upload>
        </el-form-item>
        <el-form-item label="流程图">
          <div class="step-box">
            <span>模板下载</span>
            <img src="../../assets/img/arrow.png" alt="" />
            <span>数据录入模板</span>
            <img src="../../assets/img/arrow.png" alt="" />
            <span>上传模板</span>
            <img src="../../assets/img/arrow.png" alt="" />
            <span>数据维护</span>
            <img src="../../assets/img/arrow.png" alt="" />
            <span>数据复核</span>
          </div>
        </el-form-item>
      </el-form>
      <span
        >注意：批量导入模板中“*”为必填项，其余信息可以不填，但需要在数据维护时录入，否则生成的合同信息不完成将导致不能正常收费</span
      >
      <template #footer>
        <MyButton @click="batchVisible = false">取消</MyButton>
        <MyButton type="primary" :disabled="!fileName" @click="batchImport" left
          >确认</MyButton
        >
      </template>
    </el-dialog>
    <el-dialog
      title="模板下载"
      :visible.sync="downvisible"
      @close="close"
      :close-on-click-modal="false"
    >
      <el-form
        ref="ruleForm"
        label-position="right"
        :model="downform"
        :rules="rules"
        label-width="170px"
      >
        <el-form-item label="选择合同模板:" prop="property_tpl_id">
          <el-select
            class="public-input"
            v-model="downform.property_tpl_id"
            placeholder="请选择合同模板"
          >
            <el-option
              v-for="item in contractTemplate"
              :key="item.property_setting_id"
              :value="item.property_setting_id"
              :label="item.values.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="合同最长履约年限:" prop="year_number">
          <el-input
            class="public-input"
            type="number"
            @blur="minZero('year_number')"
            v-model="downform.year_number"
            placeholder=""
          ></el-input>
        </el-form-item>
        <el-form-item label="合同每年最多缴费周期:" prop="pay_number">
          <el-input
            class="public-input"
            type="number"
            @blur="minZero('pay_number')"
            v-model="downform.pay_number"
            placeholder=""
          ></el-input>
        </el-form-item>
      </el-form>
      <p style="font-size: 12px">
        注：合同最长履约年限是指需要批量导入的所有合同中，最长履约年限是几年；
      </p>
      <p style="font-size: 12px">
        合同每年最多缴费周期是指需要批量导入的所有合同中，一年中最多缴费周期是几次;
      </p>
      <template #footer>
        <el-button @click="downvisible = false">取消</el-button>
        <el-button type="primary" @click="confirm">下载</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { json } from "body-parser";

export default {
  name: "batchcontract",
  data() {
    return {
      navList: [
        {
          name: "历史合同",
        },
      ],
      datalist: [
        {
          id: 1,
        },
      ],
      total: 0,
      form: {
        worker_id: "",
        merchants_stall_name: "",
        starttime: "",
        merchantName: "",
        endtime: "",
        contract_import_status: [10, 20],
        worker_name: "",
        pageNum: 1,
      },
      loading: false,
      batchVisible: false,
      contractstatus: [
        {
          key: 0,
          title: "待审批",
        },
        {
          key: 1,
          title: "已拒绝",
        },
        {
          key: 2,
          title: "待商户签字",
        },
        {
          key: 3,
          title: "商户已签字",
        },
        {
          key: 4,
          title: "正常",
        },
        {
          key: 5,
          title: "即将到期",
        },
        {
          key: 6,
          title: "已到期",
        },
        {
          key: 7,
          title: "已续签",
        },
      ],
      workerList: [],
      downform: {
        year_number: "",
        pay_number: "",
        property_tpl_id: "",
      },
      rules: {
        property_tpl_id: [
          { required: true, message: "请选择合同模板", trigger: "change" },
        ],
        year_number: [
          {
            required: true,
            message: "请输入合同最长履约年限",
            trigger: "blur",
          },
        ],
        pay_number: [
          {
            required: true,
            message: "请输入合同每年最多缴费周期",
            trigger: "blur",
          },
        ],
      },
      downvisible: false,
      status: "",
      fileName: null,
      contractTemplate: [],
    };
  },
  activated() {
    this.worker_id = "";
    this.merchants_stall_name = "";
    this.starttime = "";
    this.merchantName = "";
    this.endtime = "";
    this.contract_import_status = [10, 20];
    this.worker_name = "";
    this.getList();
  },

  methods: {
    async batchAudit() {
      let arr = this.$refs.multipleTable.selection;
      if (arr.length <= 0) {
        this.$common.notifyError("请先选择数据");
        return;
      }
      let data = [];
      arr.forEach((item) => {
        if (item.contract_import_status == 20) {
          data.push(item);
        }
      });
      if (data.length <= 0) {
        this.$common.notifyError("暂无需要审批的数据");
        return;
      }
      let resData = [];
      for (let i in data) {
        const res = await this.$request.HttpPost("/contract/maintain", {
          ...data[i],
          contract_increasing: data[i].contract_increasing
            ? JSON.parse(data[i].contract_increasing)
            : data[i].contract_increasing,
        });
        resData.push(res);
      }
      Promise.all(resData).then((res) => {
        this.$common.notifySuccess("审核完成");
        this.getList();
      });
    },
    del(row) {
      this.$confirm("此操作将永久删除该合同, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$request
          .HttpPost("/contract/deleteImportContract", {
            contract_id: row.contract_id,
          })
          .then((res) => {
            this.$common.notifySuccess("删除成功");
            this.getList();
          });
      });
    },
    confirm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.$myconfirm(
            "请根据下载的模板格式录入数据，不能随意修改模板格式，否则将导致上传文件无法使用!"
          ).then(() => {
            this.$exported(
              "/contract/downloadContractExcel ",
              this.downform,
              "批量合同导入模板.xlsx"
            );
            this.downvisible = false;
          });
        }
      });
    },
    getContractTemplate() {
      this.$request
        .HttpPost("/setting/list", {
          pageNum: 1,
          pageSize: 100000,
          key: "propertyTplSetting",
        })
        .then((res) => {
          if (res.data.list) {
            res.data.list.forEach((item) => {
              if (item.child_value) {
                item.child_value.values = JSON.parse(item.child_value.values);
              }
              item.values = JSON.parse(item.values);
            });
            this.contractTemplate = res.data.list;
          }
        });
    },
    close() {
      this.downform = {
        year_number: "",
        pay_number: "",
        property_tpl_id: "",
      };
    },
    handleChangePageNum(e) {
      this.form.pageNum = e;
      this.getList();
    },
    getList() {
      this.loading = true;
      this.$request
        .HttpPost("/contract/list", this.form)
        .then((res) => {
          this.loading = false;
          if (res.data && res.data.list) {
            let arr = [];
            let arrIndex = [];
            let merchants_id = "";
            let sum = 0;
            for (let i = 0; i < res.data.list.length; i++) {
              res.data.list[i].merchantStallName = [];
              res.data.list[i].merchants_stall &&
                res.data.list[i].merchants_stall.forEach((item) => {
                  res.data.list[i].merchantStallName.push(
                    item.merchants_stall_number
                  );
                });
              res.data.list[i].merchantStallName =
                res.data.list[i].merchantStallName.join(",");
              for (let j = 0; j < res.data.list.length; j++) {
                if (
                  res.data.list[i].merchants_id ==
                    res.data.list[j].merchants_id &&
                  arrIndex.indexOf(j) == -1
                ) {
                  arr.push(res.data.list[j]);
                  arrIndex.push(j);
                }
              }
            }
            for (let k = 0; k < arr.length; ) {
              merchants_id = arr[k].merchants_id;
              sum = 1;
              for (let j = parseInt(k) + 1; j < arr.length; j++) {
                if (merchants_id == arr[j].merchants_id) {
                  sum++;
                }
              }
              if (sum > 1) {
                arr[k].rowspan = sum;
                k = parseInt(k) + sum;
              } else {
                arr[k].rowspan = 1;
                k++;
              }
            }
            this.datalist = arr;
            this.total = res.data.total;
            console.log(this.datalist);
          } else {
            if (res.data.total > 0) {
              this.form.pageNum--;
              this.getList();
              return;
            }
            this.datalist = [];
            this.total = 0;
          }
        })
        .catch(() => {
          this.datalist = [];
          this.loading = false;
        });
    },
    arraySpanMethod({ row, columnIndex }) {
      // if (columnIndex == 1) {
      //   if (row.row > 1) {
      //     return {
      //       rowspan: row.row,
      //       colspan: 1,
      //     };
      //   }
      //   return [0, 0];
      // }
    },
    batchfileChange(file) {
      if (file && file.raw) {
        this.fileName = file.raw;
      }
    },
    batchImport() {
      if (!this.fileName) {
        this.$common.notifyError("请先上传文件");
        return;
      }
      let batchImportform = new FormData();
      batchImportform.append("file", this.fileName);
      this.$request
        .HttpPost("/contract/importContractExcel", batchImportform)
        .then((res) => {
          this.$refs.batchupload.clearFiles();
          this.getList();
          this.fileName = null;
          this.batchVisible = false;
        });
    },
    closebatchimport() {
      this.fileName = null;
    },
    searchstatus(e) {
      if (e) {
        this.form.contract_import_status = [e];
      } else {
        this.form.contract_import_status = [10, 20];
      }
      this.getList();
    },
    minZero(type) {
      if (this.downform[type] < 1) {
        this.downform[type] = 1;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.step-box {
  img {
    width: 50px;
    height: 10px;
    margin: 0 10px;
  }

  span {
    display: inline-block;
    // height: 10px;
    padding: 5px;
    border: 1px solid rgb(214, 212, 212);
  }
}

/deep/ .el-upload {
  text-align: left;
}
</style>
