import dayjs from "dayjs";

const caclStartDays = (dateStar, price) => {
  const startTime = dayjs(dateStar).startOf("month").format("YYYY-MM-DD");
  const totalDay = dayjs(dateStar).diff(startTime, "day");
  if (!totalDay) return price;
  const starMonDays = dayjs(dateStar).daysInMonth();
  const startAverPrice = Math.round((price / starMonDays) * 100) / 100; //每日费用
  const endOfDays = dayjs(dateStar).endOf("month").format("YYYY-MM-DD");
  const startDiffDay = dayjs(endOfDays).diff(dateStar, "day");
  const result = startAverPrice * (startDiffDay + 1);
  return result;
};
const caclEndDays = (dateEnd, price) => {
  const endTime = dayjs(dateEnd).endOf("month").format("YYYY-MM-DD");
  const totalDay = dayjs(endTime).diff(dateEnd, "day");
  if (!totalDay) return price;

  const endMonDays = dayjs(dateEnd).daysInMonth();
  const endAverPrice = Math.round((price / endMonDays) * 100) / 100; //每日费用
  const startOfDays = dayjs(dateEnd).startOf("month").format("YYYY-MM-DD");
  const endDiffDay = dayjs(dateEnd).diff(startOfDays, "day");
  const result = endAverPrice * (endDiffDay + 1);
  return result;
};
//计算两个时间相差多少
const getTotal = (dateStar, dateEnd, price) => {
  const diffMonth = dayjs(dateEnd).diff(dateStar, "month"); //相差月数
  if (diffMonth == 0) {
    const startTime = dayjs(dateStar).startOf("month").format("YYYY-MM-DD");
    const endTime = dayjs(dateEnd).endOf("month").format("YYYY-MM-DD");
    const diffTotalStar = dayjs(dateStar).diff(startTime, "day");
    const diffTotalEnd = dayjs(endTime).diff(dateEnd, "day");
    //处理 1-31 按整月处理 不然有小数
    if (!(diffTotalStar + diffTotalEnd)) {
      return price;
    }

    const monthDays = dayjs(dateEnd).daysInMonth(); //当前月份天数
    const averPrice = Math.round((price / monthDays) * 100) / 100; //每日费用
    const diffDay = dayjs(dateEnd).diff(dateStar, "day");
    const days = diffDay + 1;
    const result = averPrice * days; //包含开始结束 9 10 算两天
    return result;
  }
  if (diffMonth == 1) {
    const startMonthPrice = caclStartDays(dateStar, price);
    const endMonthPrice = caclEndDays(dateEnd, price);
    const result = Math.round((startMonthPrice + endMonthPrice) * 100) / 100;

    return result;
  }
  const fullMonth = diffMonth - 1;
  const fullMonthPrice = fullMonth * price;
  const startMonthPrice = caclStartDays(dateStar, price);
  const endMonthPrice = caclEndDays(dateEnd, price);
  const result =
    Math.round((fullMonthPrice + startMonthPrice + endMonthPrice) * 100) / 100;

  return result;
};
export { getTotal };
