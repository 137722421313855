var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal"},[_c('el-form',{ref:"formRef",attrs:{"model":{ contract_fee_id: _vm.contract_fee_id, orginInfoList: _vm.orginInfoList, count: _vm.count }}},[_c('el-row',{attrs:{"gutter":20}},[_c('div',{staticClass:"header"},[_vm._t("header",function(){return [_c('div',[_vm._v("新增附属合同")])]})],2),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"合同编号","label-width":"80px"}},[_c('div',[_vm._v(" "+_vm._s(_vm.formData.contract_no)+" ")])])],1),_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":"合同时间","label-width":"80px"}},[_c('el-date-picker',{attrs:{"start-placeholder":"合同开始时间段","end-placeholder":"合同时间段","type":"daterange","value-format":"yyyy-MM-dd"},model:{value:(_vm.contract_time),callback:function ($$v) {_vm.contract_time=$$v},expression:"contract_time"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"收费项目","label-width":"80px","prop":"contract_fee_id","rules":{
          required: _vm.isDisable ? false : true,
          message: '收费项目不能为空',
          trigger: 'blur',
        }}},[_c('el-select',{attrs:{"disabled":_vm.isDisable,"clearable":"","placeholder":"选择收费项目"},on:{"change":_vm.hanldeChangeFee},model:{value:(_vm.contract_fee_id),callback:function ($$v) {_vm.contract_fee_id=$$v},expression:"contract_fee_id"}},_vm._l((_vm.feeList),function(item){return _c('el-option',{key:item.id,attrs:{"value":item.id,"label":item.contract_fee_name}})}),1)],1)],1)],1),(_vm.repeatedType == 10)?_vm._l((_vm.stallList),function(item,index){return _c('el-row',{key:index,attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"商户编号","label-width":"80px"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(item.merchants_stall_number),callback:function ($$v) {_vm.$set(item, "merchants_stall_number", $$v)},expression:"item.merchants_stall_number"}})],1)],1),(_vm.isShowCycle)?_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"月租金","label-width":"80px"}},[_c('el-input',{attrs:{"disabled":_vm.isDisable},on:{"blur":_vm.hanldeMonthBlur},model:{value:(item.merchants_stall_price),callback:function ($$v) {_vm.$set(item, "merchants_stall_price", $$v)},expression:"item.merchants_stall_price"}})],1)],1):_vm._e()],1)}):_vm._e(),(_vm.repeatedType == 10)?_c('div',[_c('el-row',{attrs:{"gutter":20}},[(_vm.contract_fee_id && _vm.isShowCycle)?_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"缴费周期","label-width":"80px","rules":{
            required: _vm.isDisable ? false : true,
            trigger: 'blur',
            validator: _vm.checkOnceNumber,
          },"prop":"count"}},[_c('el-input-number',{staticClass:"yc-input-number",attrs:{"disabled":_vm.isDisable,"controls":false,"step":1,"min":0},on:{"blur":_vm.hanldeBlur},model:{value:(_vm.count),callback:function ($$v) {_vm.count=$$v},expression:"count"}})],1)],1):_vm._e(),(_vm.contract_fee_id && _vm.isShowCycle)?_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"总金额","label-width":"80px"}},[_c('el-input',{staticClass:"yc-input-number",attrs:{"controls":false},model:{value:(_vm.feeTotal),callback:function ($$v) {_vm.feeTotal=$$v},expression:"feeTotal"}})],1)],1):_vm._e()],1),_vm._l((_vm.orginInfoList),function(item,index){return _c('div',{key:index},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":3}},[_c('el-form-item',{attrs:{"label":"现价格","label-width":"80px","prop":'orginInfoList.' + index + '.pay_price',"rules":{
              required: _vm.isDisable ? false : true,
              trigger: 'blur',
              validator: _vm.checkNumber,
            }}},[_c('el-input-number',{staticClass:"yc-input-number",attrs:{"disabled":_vm.isDisable,"placeholder":"请输入现价格","controls":false},model:{value:(item.pay_price),callback:function ($$v) {_vm.$set(item, "pay_price", $$v)},expression:"item.pay_price"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"收费时间","label-width":"80px","prop":'orginInfoList.' + index + '.date',"rules":{
              required: _vm.isDisable ? false : true,
              message: '收费时间不能为空',
              trigger: 'blur',
            }}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"选择日期时间","value-format":"yyyy-MM-dd","picker-options":_vm.pickerRechargeOptions},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"开始时间","label-width":"80px"}},[_c('el-input',{staticClass:"yc-input-number",attrs:{"controls":false,"value":_vm.startTimeList[index]}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"结束时间","label-width":"80px","prop":'orginInfoList.' + index + '.property_end_time',"rules":{
                required: _vm.isDisable ? false : true,
                message: '结束时间不能为空',
                trigger: 'blur',
              }}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"选择日期时间","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions},on:{"focus":function($event){return _vm.hanldeDatePicker(index)},"change":function($event){return _vm.hanldeDateChange(item, index, $event)}},model:{value:(item.property_end_time),callback:function ($$v) {_vm.$set(item, "property_end_time", $$v)},expression:"item.property_end_time"}})],1)],1)],1)],1)})],2):_vm._e(),(_vm.repeatedType == 20)?_c('div',_vm._l((_vm.orginInfoList),function(item,index){return _c('div',{key:index},[_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"价格","label-width":"80px","prop":`orginInfoList.${index}.pay_price`,"rules":{
              required: _vm.isDisable ? false : true,
              message: '金额不能为空',
              trigger: 'blur',
              validator: _vm.checkOnceNumber,
            }}},[_c('el-input-number',{staticClass:"yc-input-number",attrs:{"disabled":_vm.isDisable,"placeholder":"请输入现价格","controls":false},model:{value:(item.pay_price),callback:function ($$v) {_vm.$set(item, "pay_price", $$v)},expression:"item.pay_price"}})],1)],1),_c('el-col',{attrs:{"span":6}},[_c('el-form-item',{attrs:{"label":"收费时间","label-width":"80px","prop":'orginInfoList.' + index + '.date',"rules":{
              required: _vm.isDisable ? false : true,
              message: '收费时间不能为空',
              trigger: 'blur',
            }}},[_c('el-date-picker',{attrs:{"disabled":_vm.isDisable,"type":"date","placeholder":"选择日期时间","value-format":"yyyy-MM-dd"},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})],1)],1)],1)],1)}),0):_vm._e(),_c('el-row',{attrs:{"gutter":20}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"备注","label-width":"80px"}},[_c('el-input',{attrs:{"disabled":_vm.isDisable,"type":"textarea","rows":2,"placeholder":"请输入内容"},model:{value:(_vm.formData.remarks),callback:function ($$v) {_vm.$set(_vm.formData, "remarks", $$v)},expression:"formData.remarks"}})],1)],1),(_vm.formData.changeStatus == 20 || _vm.isDetail)?_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"理由","label-width":"80px"}},[_c('el-input',{attrs:{"disabled":_vm.isDetail,"type":"textarea","rows":2,"placeholder":"请输入内容"},model:{value:(_vm.formData.contract_desc),callback:function ($$v) {_vm.$set(_vm.formData, "contract_desc", $$v)},expression:"formData.contract_desc"}})],1)],1):_vm._e()],1)],2),_c('div',{staticClass:"footer"},[_c('MyButton',{staticClass:"button",on:{"click":() => {
      _vm.$emit('cancel');
    }}},[_c('span',[_vm._v("取消")])]),(!_vm.isDetail)?_c('MyButton',{staticClass:"button",attrs:{"left":""},on:{"click":_vm.onClick}},[_c('span',[_vm._v("确认")])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }