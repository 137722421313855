<template>
  <div class="contractlist">
    <NavBar :list="navList" name="合同列表"></NavBar>
    <div class="public-box">
      <el-form :inline="true">
        <el-form-item label="" v-if="isFinance">
          <el-select v-model="form.search_market_id" placeholder="请选择市场">
            <el-option v-for="item in marketList" :key="item.market_id" :value="item.market_id"
              :label="item.market_name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="form.merchants_stall_name" placeholder="输入商户姓名">
            <template slot="pre">商户姓名:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <MyInput v-model="form.stall_name" placeholder="输入商铺号">
            <template slot="pre">商铺号:</template>
          </MyInput>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="startTime" start-placeholder="合同开始时间段" end-placeholder="合同时间段" type="daterange"
            value-format="yyyy-MM-dd" @change="timeSet('start', $event)"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="endTime" start-placeholder="合同结束时间段" end-placeholder="合同结束时间段" type="daterange"
            value-format="yyyy-MM-dd" @change="timeSet('end', $event)"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.worker_id" @change="getList(1)" clearable placeholder="选择申请人">
            <el-option v-for="item in workerList" :key="item.user_id" :value="item.user_id"
              :label="item.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="form.contract_status" @change="getList(1)" clearable placeholder="合同状态">
            <el-option v-for="item in contractstatus" :key="item.key" :value="item.key" :label="item.title"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <MyButton @click="getList">
            <span>搜索</span>
            <template slot="preImage">
              <img src="../../../assets/img/icon/search.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton :accessId="95538" @click="imported">
            导出
            <template slot="preImage">
              <img src="../../../unit/img/down.png" alt />
            </template>
          </MyButton>
        </el-form-item>
        <el-form-item>
          <MyButton :accessId="95539" @click="$router.push('/contract/terminationRecord')" title="终止合同记录">
            <template slot="preImage">
              <img src="../../../assets/img/b1.png" alt />
            </template>
          </MyButton>
        </el-form-item>
      </el-form>
      <transition>
        <AttachForm v-show="showAttach" :attachForm="attachForm" @onSubmit="hanldeSubmit" :feeList="feeList"
          @cancel="() => { showAttach = false }"></AttachForm>
      </transition>
      <div class="public-table">
        <!-- 
        :span-method="arraySpanMethod"
         -->
        <el-table :header-cell-style="{ 'text-align': 'center', background: 'rgb(245, 245, 245)' }" :cell-style="setClass"
          :row-class-name="tableRowClassName" :data="datalist" v-loading="loading" height="95%">
          <el-table-column label="商户姓名" width="150px" show-overflow-tooltip prop="merchants_stall_name">
            <template slot-scope="{ row }">
              <pop :tips="row.merchants_card_id">
                {{ row.merchants_stall_name }}
              </pop>
            </template>
          </el-table-column>
          <!-- <el-table-column label="证件号" prop="merchants_card_id" width="200px"></el-table-column> -->
          <el-table-column label="合同编号" prop="contract_no" show-overflow-tooltip></el-table-column>
          <el-table-column label="商铺号" width="220px" prop="merchantStallName"></el-table-column>
          <el-table-column label="基础费用" prop="total_price"></el-table-column>
          <el-table-column label="合同开始时间" prop="contract_start"></el-table-column>
          <el-table-column label="合同到期时间" prop="contract_end"></el-table-column>
          <el-table-column label="剩余天数" prop="differ_day" width="80px">
            <template slot-scope="{ row }">
              <span>{{ row.differ_day >= 0 ? row.differ_day : "0" }}</span>
            </template>
          </el-table-column>
          <el-table-column label="合同预终止时间">
            <template slot-scope="{ row }">
              <span>
                {{ row.pre_stop_status != -1 ? row.pre_stop_time : "" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="申请人" prop="worker_name"></el-table-column>
          <el-table-column label="合同状态">
            <template #default="{ row }">
              {{ row.contract_status_name }}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="{ row }">
              <!-- <pop tips="审批" :accessId="35006" popRight v-if="row.contract_status == 0 && row.contract_user_status == 10 && row.contract_is_agree == 10"
              @myclick="goUrl(`/contract/approval?id=${row.contract_id}`)">
              <img class="icon" src="../../../unit/img/sh.png" alt />
            </pop>
            <pop tips="审批" :accessId="35006" popRight v-else-if="(row.contract_type == 10 && row.contract_user_status == 10 && row.contract_user_is_agree == 0) || (row.contract_status == 0 && row.contract_is_agree == 0)"
              @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&forbid=sp`)">
              <img class="icon" src="../../../unit/img/sh.png" alt />
            </pop> -->
              <pop tips="撤回" :accessId="35005" popRight @myclick="backContract(row)"
                v-if="row.contract_status == 0 && row.contract_is_agree == 0">
                <img class="icon" src="../../../assets/img/back.png" alt />
              </pop>
              <pop :tips="`第一步审批`" :accessId="35006" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-if="row.contract_status == 0 && row.contract_is_agree == 0">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="第二步审批" :accessId="35007" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-else-if="row.contract_status == 0 && row.contract_is_agree == 1">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="第三步审批" :accessId="35008" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-else-if="row.contract_status == 0 && row.contract_is_agree == 2">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="第四步审批" :accessId="35013" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-else-if="row.contract_status == 0 && row.contract_is_agree == 3">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="第五步审批" :accessId="35014" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-else-if="row.contract_status == 0 && row.contract_is_agree == 4">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="第六步审批" :accessId="35015" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-else-if="row.contract_status == 0 && row.contract_is_agree == 5">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="第七步审批" :accessId="35016" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-else-if="row.contract_status == 0 && row.contract_is_agree == 6">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="第八步审批" :accessId="35017" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-else-if="row.contract_status == 0 && row.contract_is_agree == 7">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="第九步审批" :accessId="35018" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-else-if="row.contract_status == 0 && row.contract_is_agree == 8">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="第十步审批" :accessId="35019" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-else-if="row.contract_status == 0 && row.contract_is_agree == 9">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="第十一步审批" :accessId="35020" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-else-if="row.contract_status == 0 && row.contract_is_agree == 10">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="第十二步审批" :accessId="35021" popRight
                @myclick="goUrl(`/contract/editcontract?id=${row.contract_id}&status=${row.contract_is_agree}&forbid=sp`, false)"
                v-else-if="row.contract_status == 0 && row.contract_is_agree == 11">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="审批" :accessId="35009" popRight
                v-else-if="row.contract_status == 0 && row.contract_user_status == 10 && row.contract_is_agree == 12 && row.contract_user_is_agree == 0"
                @myclick="goUrl(`/contract/approval?id=${row.contract_id}&forbid=sp&status=12`)">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="编辑" :accessId="29389" popRight
                @myclick="goUrl(`/contract/addedcontract?id=${row.contract_id}&type=edit&super=1`)"
                v-if="row.contract_is_agree == 20 || row.contract_is_agree == 30">
                <img class="icon" src="../../../unit/img/edit.png" alt />
              </pop>
              <pop tips="超级编辑" :accessId="29390" popRight
                @myclick="goUrl(`/contract/addedcontract?id=${row.contract_id}&type=edit&super=1`)">
                <img class="icon" src="../../../unit/img/edit.png" alt />
              </pop>
              <pop tips="增加合同" :accessId="24012" popRight
                @myclick="goUrl(`/contract/addedcontract?id=${row.contract_id}&type=update`)"
                v-if="row.contract_status == 10">
                <img class="icon" src="../../../assets/img/icon/addHtong.png" alt />
              </pop>
              <!-- <pop tips="增加附属合同" :accessId="24013" popRight @myclick="hanldeAttachClick(row)"
                v-if="row.contract_status == 10">
                <img class="icon" src="../../../assets/img/icon/attractAdd.png" alt />
              </pop> -->
              <!-- <pop tips="详情"   popRight @myclick="goUrl(`/contract/contractdetail?id=${row.contract_id}`)" v-if="row.contract_user_is_agree != 20 && row.contract_is_agree != 20 && row.contract_status != 0 "> -->
              <pop tips="详情" :accessId="34348" popRight @myclick="goUrl(`/contract/contractdetail?id=${row.contract_id}`)"
                v-if="row.contract_status == 10 || row.contract_user_is_agree == 0 || row.contract_user_is_agree == 20">
                <img class="icon" src="../../../unit/img/xq.png" />
              </pop>
              <pop tips="删除" :accessId="26838" popRight @myclick="del(row)" v-if="row.contract_is_agree == 20">
                <img class="icon" src="../../../unit/img/sc.png" />
              </pop>
              <pop tips="超级删除" :accessId="26891" popRight @myclick="cjdel(row)">
                <img class="icon" src="../../../unit/img/sc.png" />
              </pop>
              <pop tips="预终止" :accessId="35111" popRight @myclick="prefetchAbort(row)" v-if="row.contract_status == 10 &&
                (row.pre_stop_status == -1 || row.pre_stop_status == 20)
                ">
                <img class="icon" src="../../../assets/img/icon/zhongzhi.png" />
              </pop>
              <pop tips="预终止审批第一步" :accessId="35010" popRight @myclick="prefetchAbort(row)"
                v-if="row.contract_status == 10 && row.pre_stop_status == 0">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="预终止审批第二步" :accessId="35011" popRight @myclick="prefetchAbort(row)" v-else-if="row.contract_status == 10 && row.pre_stop_status == 10
                ">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <pop tips="预终止审批第三步" :accessId="35012" popRight @myclick="prefetchAbort(row)" v-else-if="row.contract_status == 10 && row.pre_stop_status == 11
                ">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop>
              <!-- <pop tips="预览" :accessId="35012" popRight @myclick="preview(row)">
                <img class="icon" src="../../../unit/img/sh.png" alt />
              </pop> -->
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="public-page">
        <el-pagination :total="total" :current-page="1" :page-size="10" layout="total,prev,pager,next" next-text="下一页"
          prev-text="上一页" @current-change="getList"></el-pagination>
      </div>
    </div>

    <!-- 设置 -->
    <el-dialog title="设置" @close="$refs.setForm.clearValidate()" :visible.sync="settingvisible"
      :close-on-click-modal="false">
      <el-form :model="setForm" :rules="setrules" ref="setForm" label-position="right" label-width="200px">
        <el-form-item label="合同剩余天数标红显示" prop="resday">
          <MyInput v-model="setForm.resday" style="width: 240px">
            <template slot="search">天</template>
          </MyInput>
        </el-form-item>
        <el-form-item label="线下合同签订地点">
          <div style="display: flex">
            <MyInput v-model="setForm.resday" style="width: 240px"></MyInput>
            <MyButton style="margin-left: 15px" v-if="setForm.address < 1" @click="setForm.address++">添加</MyButton>
          </div>
        </el-form-item>
        <el-form-item v-for="(item, index) in setForm.address" :key="index">
          <div style="display: flex">
            <MyInput style="width: 240px"></MyInput>
            <MyButton style="margin-left: 15px" @click="setForm.address++" v-if="index == setForm.address - 1">添加
            </MyButton>
            <MyButton style="margin-left: 15px" @click="setForm.address--">删除</MyButton>
          </div>
        </el-form-item>
      </el-form>
      <template slot="footer">
        <MyButton @click="settingvisible = false">取消</MyButton>
        <MyButton type="primary" style="margin-left: 10px" @click="confirmset">确认</MyButton>
      </template>
    </el-dialog>
    <el-dialog title="终止合同审批" :visible.sync="spvisible" append-to-body :close-on-click-modal="false">
      <el-form>
        <el-form-item label="备注">
          <el-input v-model="spform.over_remark" type="textarea" placeholder=""></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="spconfirm(30)">拒绝</el-button>
        <el-button @click="spconfirm(20)" type="primary">同意</el-button>
      </template>
    </el-dialog>
    <el-dialog title="预终止合同" :visible.sync="prefetchAbortVisible" append-to-body @close="closeModel"
      :close-on-click-modal="false">
      <el-form :model="prefetchAbortParams" :rules="prefetchAbortRules" ref="prefetchAbortParams" label-position="right"
        label-width="150px">
        <el-form-item label="合同结束时间" v-if="prefetchAbortData">
          <el-date-picker v-model="prefetchAbortData.contract_end" :disabled="true" value-format="yyyy-MM-dd" type="date"
            placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="预中止时间" prop="pre_stop_time">
          <el-date-picker v-model="prefetchAbortParams.pre_stop_time" :disabled="prefetchAbortData &&
            (prefetchAbortData.pre_stop_status == -1 ||
              prefetchAbortData.pre_stop_status == 20)
            ? false
            : true
            " value-format="yyyy-MM-dd" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="是否通过" prop="pre_stop_status" v-if="prefetchAbortData &&
          prefetchAbortData.pre_stop_status != -1 &&
          prefetchAbortData.pre_stop_status != 20
          ">
          <el-radio-group v-model="prefetchAbortParams.pre_stop_status">
            <el-radio :label="10" v-if="prefetchAbortData && prefetchAbortData.pre_stop_status == 0">通过</el-radio>
            <el-radio :label="11" v-if="prefetchAbortData && prefetchAbortData.pre_stop_status == 10
              ">通过</el-radio>
            <el-radio :label="12" v-if="prefetchAbortData && prefetchAbortData.pre_stop_status == 11
              ">通过</el-radio>
            <el-radio :label="20">拒绝</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="拒绝理由" v-if="prefetchAbortData && prefetchAbortData.pre_stop_status == 20">
          <el-input v-model="prefetchAbortParams.pre_stop_desc" :disabled="true" placeholder="请输入拒绝理由"></el-input>
        </el-form-item>
        <el-form-item label="终止理由" v-if="prefetchAbortData &&
          prefetchAbortData.pre_stop_status != -1 &&
          prefetchAbortData.pre_stop_status != 20
          ">
          <el-input v-model="prefetchAbortParams.pre_stop_desc" placeholder="请输入终止理由"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="prefetchAbortParams.pre_stop_remarks" :disabled="prefetchAbortData &&
            (prefetchAbortData.pre_stop_status == -1 ||
              prefetchAbortData.pre_stop_status == 20)
            ? false
            : true
            " placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button @click="prefetchAbortVisible = false">取消</el-button>
        <el-button @click="prefetchAbortConfirm" type="primary">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import AttachForm from "./components/attachForm.vue";
export default {
  data () {
    return {
      isFinance: process.env.VUE_APP_IS_FINANCE == "-1",
      attachForm: {
        contract_no: "WWWW",
      },
      showAttach: false,
      feeList: [],
      navList: [
        {
          name: "合同列表",
        },
      ],
      datalist: [],
      form: {
        search_market_id: -1,
        worker_id: "",
        stall_name: '',
        // worker_name: '', //申请人
        merchants_stall_name: "",
        contract_start_start: "",
        contract_start_end: "",
        contract_end_start: "",
        contract_end_end: "",
        pageNum: 1,
        pageSize: 10,
        contract_status: "", //0正常1过期
      },
      total: 0,
      loading: false,
      workerList: [],
      startTime: [],
      endTime: [],
      setForm: {
        resday: "",
        address: 0,
      },
      setrules: {
        resday: [
          {
            required: true,
            message: "请输入天数",
            trigger: "change",
          },
        ],
      },
      settingvisible: false,
      refusevasible: false,
      contractstatus: [
        {
          key: 10,
          title: "正常",
        },
        {
          key: 20,
          title: "第一步审核",
        },
        {
          key: 22,
          title: "第二步审核",
        },
        {
          key: 23,
          title: "第三步审核",
        },
        {
          key: 30,
          title: "已拒绝",
        },
        {
          key: 40,
          title: "待商户签字",
        },
        {
          key: 50,
          title: "商户签字",
        },
        {
          key: 60,
          title: "商户签字-拒绝",
        },
        {
          key: 70,
          title: "即将到期",
        },
        {
          key: 80,
          title: "已到期",
        },
        {
          key: 90,
          title: "已续签",
        },
      ],
      suplusday: 0,
      spvisible: false,
      spform: {},
      prefetchAbortVisible: false,
      prefetchAbortParams: {
        contract_id: "",
        pre_stop_time: "",
        pre_stop_status: "",
        pre_stop_desc: "",
        pre_stop_remarks: "",
      },
      prefetchAbortRules: {
        pre_stop_time: [{ required: true, message: "请选择时间", trigger: "change" }],
        pre_stop_status: [{ required: true, message: "是否通过", trigger: "change" }]
      },
      prefetchAbortData: null,
      marketList: [
        {
          market_id: -1,
          market_name: "全部市场"
        }
      ],
    };
  },
  created () {
    console.log(this.isFinance);
    if (this.$route.query.status) {
      this.form.contract_status = Number(this.$route.query.status);
    }
    this.getList();
    this.getWorkerList();
    this.getsurday();
    this.getFeeList();
    this.getMarketList();
  },
  methods: {
    preview (row) {
      this.$request.HttpGet("/contract/billPreview", {
        id: row.contract_id
      }).then((res) => {
        debugger;
      });
    },
    getMarketList () {
      this.$request.HttpGet("/market/list").then((res) => {
        this.marketList = this.marketList.concat(res.data?.list || []);
        console.log("res :>> ", res);
      });
    },
    backContract (row) {
      this.$confirm("撤回操作, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$request.HttpPost("/contract/isAgreeSave", {
          contract_id: row.contract_id,
          contract_is_agree: 30,
        }).then((res) => {
          this.getList(this.form.pageNum);
        })
      });
    },
    hanldeAttachClick (row) {
      this.attachForm = { ...row };
      this.showAttach = true;
    },
    hanldeSubmit (value) {
      const params = {
        contract_id: this.attachForm.contract_id,
        contract_no: this.attachForm.contract_no,
        merchants_id: this.attachForm.merchants_id,
        contract_no: this.attachForm.contract_no,
        is_contract_vice: this.attachForm.is_contract_vice,
        remarkes: this.attachForm.remarkes,
        ...value,
      };

      this.$request.HttpPost("/contract_attach/add", params).then(() => {
        this.$common.notifySuccess("操作成功");
        this.showAttach = false;
      });
    },
    getFeeList () {
      this.$request.HttpPost("/contract_fee_setting/listAll").then((res) => {
        this.feeList = res?.data || [];
      });
    },
    closeModel () {
      this.prefetchAbortData = null;
      this.prefetchAbortParams = {
        contract_id: "",
        pre_stop_time: "",
        pre_stop_status: "",
        pre_stop_desc: "",
        pre_stop_remarks: "",
      };
    },
    prefetchAbortConfirm () {
      this.$refs["prefetchAbortParams"].validate((valid) => {
        if (valid) {
          this.$request.HttpPost("/contract/isAgreePreSave", this.prefetchAbortParams).then((res) => {
            this.$common.notifySuccess(res.msg);
            this.prefetchAbortVisible = false;
            this.getList();
          });
        }
      });
    },
    prefetchAbort (row) {
      this.$refs.prefetchAbortParams?.clearValidate();
      this.prefetchAbortParams.contract_id = row.contract_id;
      this.prefetchAbortParams.pre_stop_time = row.pre_stop_time;
      this.prefetchAbortParams.pre_stop_remarks = row.pre_stop_remarks;
      this.prefetchAbortParams.pre_stop_desc = "";
      switch (row.pre_stop_status) {
        case 0:
          this.prefetchAbortParams.pre_stop_status = 10;
          break;
        case 10:
          this.prefetchAbortParams.pre_stop_status = 11;
          break;
        case 11:
          this.prefetchAbortParams.pre_stop_status = 12;
          break;
      }
      this.prefetchAbortData = row;
      this.prefetchAbortVisible = true;
    },
    del (row) {
      this.$myconfirm("此操作将永久删除该合同, 是否继续?", "提示").then(() => {
        this.$request.HttpPost("/contract/delete", {
          ids: [row.contract_id],
        }).then((res) => {
          this.$common.notifySuccess(res.msg);
          this.getList();
        });
      });
    },
    cjdel (row) {
      this.$myconfirm("此操作将永久删除该合同, 是否继续?", "提示").then(() => {
        this.$request.HttpPost("/contract/contractDelete", {
          id: row.contract_id,
        }).then((res) => {
          this.$common.notifySuccess(res.msg);
          this.getList();
        });
      });
    },
    // 获取剩余天数设置
    getsurday () {
      this.$request.HttpGet("/setting/edit?key=contractWarningSetting").then((res) => {
        if (res.data && res.data.values) {
          this.suplusday = res.data.values.surplus_day;
        }
      });
    },
    getList (e = 1) {
      this.loading = true;
      this.form.pageNum = e;
      this.$request.HttpGet("/contract/list", this.form).then((res) => {
        this.loading = false;
        if (res.data && res.data.list) {
          let arr = [];
          let arrIndex = [];
          let merchants_id = "";
          let sum = 0;
          for (let i = 0; i < res.data.list.length; i++) {
            res.data.list[i].merchantStallName = [];
            res.data.list[i].merchants_stall &&
              res.data.list[i].merchants_stall.forEach((item) => {
                res.data.list[i].merchantStallName.push(
                  item.merchants_stall_number
                );
              });
            res.data.list[i].merchantStallName = res.data.list[i].merchantStallName.join(",");
            for (let j = 0; j < res.data.list.length; j++) {
              if (res.data.list[i].merchants_id == res.data.list[j].merchants_id && arrIndex.indexOf(j) == -1) {
                arr.push(res.data.list[j]);
                arrIndex.push(j);
              }
            }
          }
          for (let k = 0; k < arr.length;) {
            merchants_id = arr[k].merchants_id;
            sum = 1;
            for (let j = parseInt(k) + 1; j < arr.length; j++) {
              if (merchants_id == arr[j].merchants_id) {
                sum++;
              }
            }
            if (sum > 1) {
              arr[k].rowspan = sum;
              k = parseInt(k) + sum;
            } else {
              arr[k].rowspan = 1;
              k++;
            }
          }
          this.datalist = arr;
          this.total = res.data.total;
        } else {
          this.datalist = [];
          this.total = 0;
        }
      }).catch(() => {
        this.datalist = [];
        this.loading = false;
      });
    },

    // 设置首个颜色
    setClass ({ columnIndex }) {
      if (columnIndex == 0) {
        return {
          background: "white",
          "text-align": "center",
          color: "black",
        };
      }
      return {
        "text-align": "center",
      };
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.contract_status_name == "即将到期") {
        return "erro-row";
      }
      if (row.contract_status_name == "正常" || row.contract_status_name == "已续签") {
        return "";
      } else if (row.contract_status_name == "已到期") {
        return "other-row";
      } else {
        return "yellow-row";
      }
    },
    arraySpanMethod ({ row, columnIndex }) {
      if (columnIndex == 0) {
        if (row.rowspan > 0) {
          return {
            rowspan: row.rowspan,
            colspan: 1,
          };
        }
        return [0, 0];
      }
    },
    // 获取申请人
    getWorkerList () {
      this.$request.HttpGet("/worker/list").then((res) => {
        if (res.data) {
          this.workerList = res.data.list;
        }
      });
    },
    timeSet (type, date) {
      if (date) {
        type == "start" ? (() => {
          this.form.contract_start_start = date[0];
          this.form.contract_start_end = date[1];
        })() : (() => {
          this.form.contract_end_start = date[0];
          this.form.contract_end_end = date[1];
        })();
      } else {
        type == "start" ? (() => {
          this.form.contract_start_start = "";
          this.form.contract_start_end = "";
        })() : (() => {
          this.form.contract_end_start = "";
          this.form.contract_end_end = "";
        })();
      }
    },
    imported () {
      this.$exported("/contract/export", this.form, "合同列表.xlsx");
    },
    confirmset () {
      this.$refs.setForm.validate();
    },
    goUrl (url, type = true) {
      if (type) {
        this.$openNewTab(url);
      } else {
        this.$router.push(url);
      }
    },
    handlesh (item) {
      this.spform.agree_contract_id = item.contract_id;
      this.spvisible = true;
    },
    spconfirm (type) {
      this.spform.over_is_agree = type;
      this.$request.HttpPost("/contract_agree/isAgreeEdit", this.spform).then((res) => {
        this.spvisible = false;
        this.getList();
        this.$emit("refresh");
      });
    },
  },
  components: { AttachForm },
};
</script>
<style lang="scss" scoped>
.textarea {
  width: 100%;
  height: 200px;
  border: 1px solid #f0eaea;
}

.qzitem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;

  div {
    div {
      display: flex;
      align-items: center;
      //    margin-bottom: 15px;
    }
  }
}

/deep/ .el-table .erro-row {
  background: #f7c3c3;
  // color: #e4e4e4;
  // color: #FFFF;
}

/deep/ .el-table .other-row {
  background: skyblue !important;
}

/deep/ .el-table .yellow-row {
  background: yellowgreen !important;
}

.modal {
  border: 1px solid #eaeaea;
}
</style>
